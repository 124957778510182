import * as R from "ramda"

import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"

import initialState, { StateShape } from "models/initialState"
import { Actions as uiActions } from "models/ui"

import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"
import {
  extractNormalizedUserProfilesFromProvider,
  extractProfileIdsFromNormalizedProvider,
  extractListOfProfileIdsFromNormalizedProvider,
} from "utils/profiles"

import { EMAIL_NEEDS_CONFIRMATION_MESSAGE } from "constants/errors"

import { Username } from "types/common"
import { ToastType } from "types/ui"
import { AuthAction, AuthActionType } from "types/auth"
import {
  ProvidersActionType,
  MultipleProvidersSuccessAction,
  // Provider,
  ProviderFull,
  NormalizedProvider,
  SingleProviderSuccessAction,
  NormalizedProviderWithEvents,
} from "types/providers"
import {
  Profile,
  ProfileID,
  ProfileState,
  ProfilesAction,
  ProfileUpdate,
  ProfilesActionType,
  GenericProfileAction,
  FetchUserProfileAction,
  UpdateUserProfileAction,
  SingleProfileSuccessAction,
  SingleProfileFailureAction,
  NormalizedProfile,
  PopulatedProfile,
} from "types/user"
import { isStringOrNumber } from "utils/parseUtils"
import { isEventInstance } from "utils/events"

const updateStateAfterFailedProfileAction = (state: ProfileState, action: SingleProfileFailureAction) => {
  return {
    ...state,
    isFetching: false,
    isUpdating: false,
    error: action.error,
  }
}

const updateStateAfterSuccessfulProfileAction = (state: ProfileState, action: SingleProfileSuccessAction) => {
  return {
    ...state,
    isFetching: false,
    isUpdating: false,
    error: null,
    data: {
      ...state.data,
      [action?.data?.id]: action.data,
    },
    usernameToIdLookup: {
      ...state.usernameToIdLookup,
      // index the fetched user's id by username for easier retrieval next time
      [action.data?.username]: action.data?.id,
    },
    usernameToMetaLookup: {
      ...state.usernameToMetaLookup,
      [action.data?.username]: {
        lastFetchedAt: new Date().toISOString(),
        fourOhFour: false,
      },
    },
  }
}

const updateStateWithMultipleNormalizedProfiles = (state: ProfileState, normalizedProfiles: NormalizedProfile[]) => {
  const idToProfilesMapping = R.indexBy((p) => R.prop("id", p), normalizedProfiles)
  const usernameToProfilesMapping = R.indexBy((p) => R.prop("username", p), normalizedProfiles)
  const usernameToProfileIdLookup = R.mapObjIndexed((p) => R.view(R.lensProp("id"), p), usernameToProfilesMapping)

  const data = {
    ...state.data,
    ...idToProfilesMapping,
  }
  const usernameToIdLookup = {
    ...state.usernameToIdLookup,
    ...usernameToProfileIdLookup,
  }
  const usernameToMetaLookup = {
    ...state.usernameToMetaLookup,
    ...R.mapObjIndexed(
      () => ({
        lastFetchedAt: new Date().toISOString(),
        fourOhFour: false,
      }),
      usernameToProfilesMapping
    ),
  }

  return {
    ...state,
    data,
    usernameToIdLookup,
    usernameToMetaLookup,
  }
}

const updateStateAfterSuccessfullyFetchingMultipleProviders = (
  state: ProfileState,
  action: MultipleProvidersSuccessAction
) => {
  const normalizedProfiles: NormalizedProfile[] = R.flatten(
    R.map(extractNormalizedUserProfilesFromProvider, action.data)
  )

  return updateStateWithMultipleNormalizedProfiles(state, normalizedProfiles)
}

const updateStateAfterSuccessfullyFetchingSingleProvider = (
  state: ProfileState,
  action: SingleProviderSuccessAction
) => {
  const normalizedProfiles: NormalizedProfile[] = extractNormalizedUserProfilesFromProvider(action.data as ProviderFull)

  return updateStateWithMultipleNormalizedProfiles(state, normalizedProfiles)
}

/**
 * PROFILES REDUCER
 */
export default function profilesReducer(
  state = initialState.profiles,
  action: ProfilesAction | AuthAction | MultipleProvidersSuccessAction | SingleProviderSuccessAction
) {
  switch (action.type) {
    case ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleProviders(state, action)
    case ProvidersActionType.FETCH_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingSingleProvider(state, action)
    case ProvidersActionType.CREATE_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingSingleProvider(state, action)
    case ProvidersActionType.UPDATE_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingSingleProvider(state, action)

    case ProfilesActionType.FETCH_USER_PROFILE:
      return { ...state, isFetching: true }
    case ProfilesActionType.FETCH_USER_PROFILE_SUCCESS:
      return updateStateAfterSuccessfulProfileAction(state, action)
    case ProfilesActionType.FETCH_USER_PROFILE_FAILURE:
      return updateStateAfterFailedProfileAction(state, action)
    case ProfilesActionType.UPDATE_PROFILE:
      return { ...state, isUpdating: true }
    case ProfilesActionType.UPDATE_PROFILE_SUCCESS:
      return updateStateAfterSuccessfulProfileAction(state, action)
    case ProfilesActionType.UPDATE_PROFILE_FAILURE:
      return updateStateAfterFailedProfileAction(state, action)
    case ProfilesActionType.SET_PROFILE_FOUR_OH_FOUR_USERNAME:
      return {
        ...state,
        usernameToMetaLookup: {
          ...state.usernameToMetaLookup,
          [action.username]: {
            lastFetchedAt: new Date().toISOString(),
            fourOhFour: true,
          },
        },
      }
    case ProfilesActionType.CLEAR_PROFILE_STATE:
      return initialState.profiles
    case AuthActionType.SIGN_USER_OUT:
      return initialState.events
    default:
      return state
  }
}

/**
 * PROFILES ACTIONS
 */
const clearProfileState = () => ({ type: ProfilesActionType.CLEAR_PROFILE_STATE })
const setProfileFourOhFourUsername = (username: Username) => ({
  type: ProfilesActionType.SET_PROFILE_FOUR_OH_FOUR_USERNAME,
  data: username,
})

export type FetchUserProfileParams = { username: Username }
export type FetchUserProfileResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchUserProfileAction | GenericProfileAction
>
const fetchUserProfile = ({ username }: FetchUserProfileParams): FetchUserProfileResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/profiles/${username}/`,
        method: "GET",
        types: {
          REQUEST: ProfilesActionType.FETCH_USER_PROFILE,
          SUCCESS: ProfilesActionType.FETCH_USER_PROFILE_SUCCESS,
          FAILURE: ProfilesActionType.FETCH_USER_PROFILE_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => {
          dispatch(Actions.setProfileFourOhFourUsername(username) as GenericProfileAction)

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export type UpdateProfileParams = { profileUpdate: ProfileUpdate }
export type UpdateProfileResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  UpdateUserProfileAction
>
const updateProfile = ({ profileUpdate }: UpdateProfileParams): UpdateProfileResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/profiles/me/`,
        method: "PUT",
        types: {
          REQUEST: ProfilesActionType.UPDATE_PROFILE,
          SUCCESS: ProfilesActionType.UPDATE_PROFILE_SUCCESS,
          FAILURE: ProfilesActionType.UPDATE_PROFILE_FAILURE,
        },
        options: {
          data: { profile_update: profileUpdate },
          params: {},
        },
        onSuccess: (res) => {
          const toast = {
            title: "Updated!",
            contents: "Your profile was updated successfully.",
            type: ToastType.SUCCESS,
          }
          dispatch(uiActions.addUiToast({ toast }))

          return { success: true, status: res.status, data: res.data }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              title: "Unable to update profile.",
              contents: makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
              link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

// export type SocialFollowResult = ThunkAction<
//   Promise<ApiClientCallbackResponse>,
//   StateShape,
//   void,
//   FollowAndUnfollowAction | UpdateFollowersAndFollowingAction
// >
// const followUser = ({ username }: { username: string }): SocialFollowResult => {
//   return (dispatch, getState) => {
//     const { auth } = getState()
//     const authedUserProfileId = auth?.user?.profile

//     return dispatch(
//       apiClient({
//         url: `/profiles/${username}/follow/`,
//         method: "POST",
//         types: {
//           REQUEST: ProfilesActionType.FOLLOW_USER,
//           SUCCESS: ProfilesActionType.FOLLOW_USER_SUCCESS,
//           FAILURE: ProfilesActionType.FOLLOW_USER_FAILURE,
//         },
//         options: {
//           data: {},
//           params: {},
//         },
//         onSuccess: (res) => {
//           dispatch(Actions.optimisticallyUpdateUserFollowersCount(username, +1) as UpdateFollowersAndFollowingAction)
//           dispatch(
//             Actions.optimisticallyUpdateAuthedUserFollowingCount(
//               authedUserProfileId as UserID,
//               +1
//             ) as UpdateFollowersAndFollowingAction
//           )

//           return { success: true, status: res.status, data: res.data }
//         },
//         onFailure: (res) => {
//           const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

//           const errorMessages = extractErrorMessages(error)

//           errorMessages.forEach((error) => {
//             const toast = {
//               title: "Unable to follow user.",
//               contents: makeUserFriendlyErrorMessage(error),
//               type: "danger",
//               link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
//             }
//             dispatch(uiActions.addUiToast({ toast }))
//           })

//           return { success: false, status: res.status, error: res.error }
//         },
//       })
//     )
//   }
// }

// const unfollowUser = ({ username }: { username: string }): SocialFollowResult => {
//   return (dispatch, getState) => {
//     const { auth } = getState()
//     const authedUserProfileId = auth?.user?.profile

//     return dispatch(
//       apiClient({
//         url: `/profiles/${username}/unfollow/`,
//         method: "DELETE",
//         types: {
//           REQUEST: ProfilesActionType.UNFOLLOW_USER,
//           SUCCESS: ProfilesActionType.UNFOLLOW_USER_SUCCESS,
//           FAILURE: ProfilesActionType.UNFOLLOW_USER_FAILURE,
//         },
//         options: {
//           data: {},
//           params: {},
//         },
//         onSuccess: (res) => {
//           dispatch(Actions.optimisticallyUpdateUserFollowersCount(username, -1) as UpdateFollowersAndFollowingAction)
//           dispatch(
//             Actions.optimisticallyUpdateAuthedUserFollowingCount(
//               authedUserProfileId as UserID,
//               -1
//             ) as UpdateFollowersAndFollowingAction
//           )

//           return { success: true, status: res.status, data: res.data }
//         },
//         onFailure: (res) => {
//           const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

//           const errorMessages = extractErrorMessages(error)

//           errorMessages.forEach((error) => {
//             const toast = {
//               title: "Can't unfollow user.",
//               contents: makeUserFriendlyErrorMessage(error),
//               type: "danger",
//               link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
//             }
//             dispatch(uiActions.addUiToast({ toast }))
//           })

//           return { success: false, status: res.status, error: res.error }
//         },
//       })
//     )
//   }
// }

export const Actions = {
  clearProfileState,
  setProfileFourOhFourUsername,
  fetchUserProfile,
  // optimisticallyUpdateUserFollowersCount,
  // optimisticallyUpdateAuthedUserFollowingCount,
  updateProfile,
  // followUser,
  // unfollowUser,
}
// import { extractNormalizedUserProfilesFromProvider, extractProfileIdsFromNormalizedProvider, extractListOfProfileIdsFromNormalizedProvider } from "utils/profiles"

/**
 * PROFILES SELECTORS
 */
export const selectProfilesFromProfileIds = (state: StateShape, profileIds: ProfileID[]): NormalizedProfile[] => {
  return R.values(R.pick(profileIds, state.profiles.data))
}

export const selectProfilesForProvider = (
  state: StateShape,
  provider: NormalizedProvider | NormalizedProviderWithEvents
): { admin: NormalizedProfile[]; members: NormalizedProfile[]; owner: NormalizedProfile } => {
  const { admin, members, owner } = extractProfileIdsFromNormalizedProvider(provider)

  return {
    admin: selectProfilesFromProfileIds(state, admin),
    members: selectProfilesFromProfileIds(state, members),
    owner: state.profiles.data[owner],
  }
}

export const selectListOfProfilesForProvider = (
  state: StateShape,
  provider: NormalizedProvider | NormalizedProviderWithEvents
): NormalizedProfile[] => {
  const profileIds = extractListOfProfileIdsFromNormalizedProvider(provider)
  return selectProfilesFromProfileIds(state, profileIds)
}

export const populateNormalizedProfile = (state: StateShape, profile: NormalizedProfile): PopulatedProfile => {
  const event_preview_ids = profile.event_previews.map((e) => (isStringOrNumber(e) ? e : isEventInstance(e) ? e.id : e))
  const profileEvents = R.pick(event_preview_ids, state.events.data)
  return { ...profile, event_previews: R.values(profileEvents), reviews: [] }
}

export const selectPopulatedProfile = (state: StateShape, profile: NormalizedProfile): PopulatedProfile | null => {
  return R.isNil(profile) ? profile : populateNormalizedProfile(state, profile)
}
