import { ID, Email, Image, Message, ResourceMeta, Username, Timestamp } from "types/common"
import { EventID, EventPreview, NormalizedEvent } from "types/events"

export type UserID = ID
export type PreferenceID = ID
export type ProfileID = ID

// export interface EventPreview {
//   image: null,
//   imgix_src: null,
//   id: 1,
//   slug: 'upperline-teacher-training',
//   name: 'Upperline Teacher Training',
//   provider: 1,
//   event_type: 'immersive',
//   is_owner: true,
//   attended: false,
//   reviewed: false,
//   is_registered: false
// }

export interface Profile {
  id?: ProfileID
  full_name?: string
  username?: Username
  email?: Email
  bio?: string
  image?: string
  imgix_src?: string
  is_social?: boolean
  location?: Location
  num_following?: number
  num_followers?: number
  following?: boolean

  // reviews?: ReviewSimple[]
  event_previews?: EventPreview[]
  created_at?: Timestamp
  updated_at?: Timestamp
}
export interface ProfileUpdate {
  full_name?: string
  phone_number?: string
  bio?: string
  image?: Image
}

export interface UserPreferences {
  id: PreferenceID
  has_set_preferences: boolean
  receives_emails: boolean
  receives_texts: boolean
  created_at: Timestamp
  updated_at: Timestamp
  user_id: UserID
}
export interface UserBase {
  id: UserID
  username: Username
  email: Email
  email_verified: boolean
  created_at: Timestamp
  updated_at: Timestamp
}
export interface UserAvatar extends UserBase {
  profile: Profile
}
export interface UserFull extends UserBase {
  profile: Profile
  preferences?: UserPreferences
}
export interface UserTruncated extends UserBase {
  profile: ProfileID
}
export type User = UserFull | UserTruncated

export interface NextAuthUserInfo {
  user: UserFull
  email?: string
  iat?: number
  exp?: number
}

export interface NextAuthUserSession {
  user: {
    name?: any
    email?: string
    image?: any
  }
  expires?: string | Date
  userInfo?: NextAuthUserInfo
  accessToken?: any
}

export interface NormalizedProfile extends Omit<Profile, "event_previews"> {
  event_previews: EventID[] | EventPreview[]
}

export interface PopulatedProfile extends Omit<Profile, "event_previews"> {
  event_previews: NormalizedEvent[]
  reviews: Array<any>
}

export interface ProfileState {
  isFetching: boolean
  isUpdating: boolean
  error: Error
  data: Record<ProfileID, NormalizedProfile>
  usernameToIdLookup: Record<Username, ProfileID>
  usernameToMetaLookup: Record<Username, ResourceMeta>
}

export enum ProfilesActionType {
  CLEAR_PROFILE_STATE = "@@profiles/CLEAR_PROFILE_STATE",
  // CLEAR_CURRENT_PROFILE = "@@profiles/CLEAR_CURRENT_PROFILE",
  SET_PROFILE_FOUR_OH_FOUR_USERNAME = "@@profiles/SET_PROFILE_FOUR_OH_FOUR_USERNAME",
  // SET_PROFILE_AS_CURRENT_PROFILE = "@@profiles/SET_PROFILE_AS_CURRENT_PROFILE",

  FETCH_USER_PROFILE = "@@profiles/FETCH_USER_PROFILE",
  FETCH_USER_PROFILE_SUCCESS = "@@profiles/FETCH_USER_PROFILE_SUCCESS",
  FETCH_USER_PROFILE_FAILURE = "@@profiles/FETCH_USER_PROFILE_FAILURE",

  UPDATE_PROFILE = "@@profiles/UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS = "@@profiles/UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE = "@@profiles/UPDATE_PROFILE_FAILURE",

  FOLLOW_USER = "@@profiles/FOLLOW_USER",
  FOLLOW_USER_SUCCESS = "@@profiles/FOLLOW_USER_SUCCESS",
  FOLLOW_USER_FAILURE = "@@profiles/FOLLOW_USER_FAILURE",

  UNFOLLOW_USER = "@@profiles/UNFOLLOW_USER",
  UNFOLLOW_USER_SUCCESS = "@@profiles/UNFOLLOW_USER_SUCCESS",
  UNFOLLOW_USER_FAILURE = "@@profiles/UNFOLLOW_USER_FAILURE",

  UPDATE_USER_FOLLOWERS_COUNT = "@@profiles/UPDATE_USER_FOLLOWERS_COUNT",
  UPDATE_AUTHED_USER_FOLLOWING_COUNT = "@@profiles/UPDATE_AUTHED_USER_FOLLOWING_COUNT",
}

// GENERIC PROFILE
export interface IClearProfileState {
  type: ProfilesActionType.CLEAR_PROFILE_STATE
}
export interface ISetProfileFourOhFourUsername {
  type: ProfilesActionType.SET_PROFILE_FOUR_OH_FOUR_USERNAME
  username: Username
}
export type GenericProfileAction = IClearProfileState | ISetProfileFourOhFourUsername

// FETCH USER PROFILE
export interface IFetchUserProfile {
  type: ProfilesActionType.FETCH_USER_PROFILE
}
export interface IFetchUserProfileSuccess {
  type: ProfilesActionType.FETCH_USER_PROFILE_SUCCESS
  data: Profile
}
export interface IFetchUserProfileFailure {
  type: ProfilesActionType.FETCH_USER_PROFILE_FAILURE
  error: Error
}

export type FetchUserProfileAction = IFetchUserProfile | IFetchUserProfileSuccess | IFetchUserProfileFailure

// UPDATE USER PROFILE
export interface IUpdateProfile {
  type: ProfilesActionType.UPDATE_PROFILE
}
export interface IUpdateProfileSuccess {
  type: ProfilesActionType.UPDATE_PROFILE_SUCCESS
  data: Profile
}
export interface IUpdateProfileFailure {
  type: ProfilesActionType.UPDATE_PROFILE_FAILURE
  error: Error
}
export type UpdateUserProfileAction = IUpdateProfile | IUpdateProfileSuccess | IUpdateProfileFailure

// FOLLOW AND UNFOLLOW USER
export interface IFollowUser {
  type: ProfilesActionType.FOLLOW_USER
}
export interface IFollowUserSuccess {
  type: ProfilesActionType.FOLLOW_USER_SUCCESS
  data: Message
}
export interface IFollowUserFailure {
  type: ProfilesActionType.FOLLOW_USER_FAILURE
  error: Error
}
export interface IUnfollowUser {
  type: ProfilesActionType.UNFOLLOW_USER
}
export interface IUnfollowUserSuccess {
  type: ProfilesActionType.UNFOLLOW_USER_SUCCESS
  data: Message
}
export interface IUnfollowUserFailure {
  type: ProfilesActionType.UNFOLLOW_USER_FAILURE
  error: Error
}

export type FollowAndUnfollowAction =
  | IFollowUser
  | IFollowUserSuccess
  | IFollowUserFailure
  | IUnfollowUser
  | IUnfollowUserSuccess
  | IUnfollowUserFailure

export interface IUpdateUsersFollowersCount {
  type: ProfilesActionType.UPDATE_USER_FOLLOWERS_COUNT
  username?: Username
  increment?: number
}
export interface IUpdateAuthedUsersFollowingCount {
  type: ProfilesActionType.UPDATE_AUTHED_USER_FOLLOWING_COUNT
  authedUserId?: UserID
  increment?: number
}
export type UpdateFollowersAndFollowingAction = IUpdateUsersFollowersCount | IUpdateAuthedUsersFollowingCount

export type SocialAction = FollowAndUnfollowAction | UpdateFollowersAndFollowingAction

export type ProfilesAction = GenericProfileAction | FetchUserProfileAction | UpdateUserProfileAction | SocialAction

export type SingleProfileSuccessAction = IFetchUserProfileSuccess | IUpdateProfileSuccess

export type SingleProfileFailureAction = IFetchUserProfileFailure | IUpdateProfileFailure

// const Thing = {
//   user: { name: null, email: "miss@piggy.io", image: null },
//   expires: "2021-03-22T19:37:39.418Z",
//   userInfo: {
//     email: "miss@piggy.io",
//     user: {
//       email: "miss@piggy.io",
//       username: "miss_piggy",
//       created_at: "2021-03-14T06:41:36.301648+00:00",
//       updated_at: "2021-03-14T06:41:36.301648+00:00",
//       id: 12,
//       email_verified: false,
//       is_active: true,
//       is_superuser: false,
//       access_token: null,
//       profile: {
//         full_name: null,
//         phone_number: null,
//         bio: null,
//         image: null,
//         location: null,
//         created_at: "2021-03-14T06:41:36.301648+00:00",
//         updated_at: "2021-03-14T06:41:36.301648+00:00",
//         id: 12,
//         user_id: 12,
//         username: "miss_piggy",
//         email: "miss@piggy.io",
//         address: null,
//         imgix_src: null,
//         is_social: false,
//         reviews: [],
//         event_previews: [],
//         following: false,
//         num_followers: 0,
//         num_following: 0,
//       },
//       preferences: {
//         has_set_preferences: false,
//         receives_emails: false,
//         receives_texts: false,
//         created_at: "2021-03-14T06:41:36.301648+00:00",
//         updated_at: "2021-03-14T06:41:36.301648+00:00",
//         id: 12,
//         user_id: 12,
//       },
//       velupAccessToken:
//         "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtaXNzQHBpZ2d5LmlvIiwidXNlcm5hbWUiOiJtaXNzX3BpZ2d5IiwiaXNzIjoidmVsdXAuaW8iLCJhdWQiOiJ2ZWx1cDp1c2VycyIsImlhdCI6MTYxNTg1MTQ0Ny45MTQ0MDYsImV4cCI6MTYxNjQ1NjI0Ny45MTU1MDN9.tArD34Bg9Z49dMepS7JHjWEyqiZ45LfqY-PzMtqpUiA",
//     },
//     iat: 1615837048,
//     exp: 1618429048,
//   },
// }

// const thing = {
//   email: "miss@piggy.io",
//   profile: {
//     email: "miss@piggy.io",
//     username: "miss_piggy",
//     created_at: "2021-03-14T06:41:36.301648+00:00",
//     updated_at: "2021-03-14T06:41:36.301648+00:00",
//     id: 12,
//     email_verified: false,
//     is_active: true,
//     is_superuser: false,
//     access_token: null,
//     profile: {
//       full_name: null,
//       phone_number: null,
//       bio: null,
//       image: null,
//       location: null,
//       created_at: "2021-03-14T06:41:36.301648+00:00",
//       updated_at: "2021-03-14T06:41:36.301648+00:00",
//       id: 12,
//       user_id: 12,
//       username: "miss_piggy",
//       email: "miss@piggy.io",
//       address: null,
//       imgix_src: null,
//       is_social: false,
//       reviews: [],
//       event_previews: [],
//       following: false,
//       num_followers: 0,
//       num_following: 0,
//     },
//     preferences: {
//       has_set_preferences: false,
//       receives_emails: false,
//       receives_texts: false,
//       created_at: "2021-03-14T06:41:36.301648+00:00",
//       updated_at: "2021-03-14T06:41:36.301648+00:00",
//       id: 12,
//       user_id: 12,
//     },
//     velupAccessToken:
//       "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtaXNzQHBpZ2d5LmlvIiwidXNlcm5hbWUiOiJtaXNzX3BpZ2d5IiwiaXNzIjoidmVsdXAuaW8iLCJhdWQiOiJ2ZWx1cDp1c2VycyIsImlhdCI6MTYxNTg0MjExNy42NjQ3MTcsImV4cCI6MTYxNjQ0NjkxNy42NjQ3NTR9.vY6D3AW8aA5KpCuVk1KXyhfuc45pC3NThyjmSUbZifU",
//   },
//   iat: 1615835745,
//   exp: 1618427745,
// }
